<template>
    <div class="bg-white">

            <div class="page-title-large">
                <h1 class="section-title">A sneak peek into Sabasi</h1>
            </div>

            <!-- User dashboard -->
            <section class="feature-large feature-large-odd">
                <div class="container-lg">
                    <div class="row g-0">
                        <div class="col-lg-6">
                            <div class="feature-large-img">
                                <img loading="lazy" src="@/assets/img/landing/dashboard-preview.png" alt="Preview of the dashboard" />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="feature-large-info">
                                <h4>User dashboard</h4>
                                <p>The user dashboard allows you to view a summary of your activity on the application.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Form builder -->
            <section class="feature-large feature-large-even">
                <div class="container-lg">
                    <div class="row g-0">
                        <div class="col-lg-6 order-2 order-lg-1">
                            <div class="feature-large-info">
                                <h4>Simple form builder</h4>
                                <ul>
                                    <li>Easily add, edit or delete questions using the form builder</li>
                                    <li>Choose from a variety of question types</li>
                                    <li>Create your form in different languages (English, French or Swahili)</li>
                                    <li>Add conditional logic to your flow of questions</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 order-1 order-lg-2">
                            <div class="feature-large-img">
                                <img loading="lazy" src="@/assets/img/landing/form-builder-preview.png" alt="Preview of the form builder" />
                            </div>
                        </div>                        
                    </div>
                </div>
            </section>   

            <!-- Responses -->
            <section class="feature-large feature-large-odd">
                <div class="container-lg">
                    <div class="row g-0">
                        <div class="col-lg-6">
                            <div class="feature-large-img">
                                <img loading="lazy" src="@/assets/img/landing/table-responses-preview.png" alt="Preview of the responses" />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="feature-large-info">
                                <h4>Flexible response views</h4>
                                <ul>
                                    <li>Switch between list, table and map views for your survey responses</li>
                                    <li>Export your responses to CSV or PDF</li>
                                    <li>Search and filter responses</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>        

            <!-- Reports -->
            <section class="feature-large feature-large-even">
                <div class="container-lg">
                    <div class="row g-0">
                        <div class="col-lg-6 order-2 order-lg-1">
                            <div class="feature-large-info">
                                <h4>Real-time reports</h4>
                                <ul>
                                    <li>Generate real-time reports as responses are received</li>
                                    <li>Export your responses to CSV for viewing in a spreadsheet programme</li>
                                    <li>Switch between bar chart, pie chart and table views</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 order-1 order-lg-2">
                            <div class="feature-large-img">
                                <img loading="lazy" src="@/assets/img/landing/reports-preview.png" alt="Preview of the reports" />
                            </div>
                        </div>                        
                    </div>
                </div>
            </section>     

            <!-- Mobile -->
            <section class="feature-large feature-large-odd">
                <div class="container-lg">
                    <div class="row g-0">
                        <div class="col-lg-6 col-md-6 order-2 order-lg-1">
                            <div class="feature-large-img">
                                <img class="feature-large-img__mobile" loading="lazy" src="@/assets/img/landing/bluetooth-exports-preview.png" alt="Preview of bluetooth exports on mobile" />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 order-1 order-lg-2">
                            <div class="feature-large-info">
                                <h4>Save your responses when you go offline</h4>
                                <p>in case you have a poor Internet connection, or your’re experiencing a network failure, your survey response will be saved offline.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>      

            <!-- Geolocation -->
            <section class="feature-large feature-large-even">
                <div class="container-lg">
                    <div class="row g-0">
                        <div class="col-lg-6 order-2 order-lg-1">
                            <div class="feature-large-info">
                                <h4>Geolocation</h4>
                                <ul>
                                    <li>Enable or disable geolocation for your respondents</li>
                                    <li>Display your respondents in a map</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 order-1 order-lg-2">
                            <div class="feature-large-img">
                                <img loading="lazy" src="@/assets/img/landing/response-map-preview.png" alt="Preview of the respondents map view" />
                            </div>
                        </div>                        
                    </div>
                </div>
            </section>                             

            <!-- CTA -->
            <section class="cta">
                <div class="container-lg">
                    <div class="cta-inner">
                        <h2 class="section-title">Get started now for free.</h2>
                        <p>Want to create awesome surveys? Sign up for free on the web or download the mobile app today.</p>
                        <div class="cta-buttons">
                            <router-link to="/register">
                                <button class="hero-btn hero-btn-secondary">Sign Up</button>
                            </router-link>
                            <a href="https://play.google.com/store/apps/details?id=com.sabasi.mobile" target="_blank" title="Download Sabasi on Google Play">
                                <img class="appstore-badge" src="@/assets/img/google-play-badge.png" alt="Google Play badge" />
                            </a>
                        </div>
                    </div>
                </div>
            </section>                     

    </div>
</template>

<script>
export default {
    name: 'Features',
    methods: {
        auth() {
            return localStorage.getItem(process.env.VUE_APP_NAME + "_token") != null;
        }
    }
}
</script>

<style scoped>
</style>